import { Content } from "@components/BlogPage";
import { HomepageBanner } from "@components/homepage/banner";
import { PaymentBanner } from "@components/homepage/payment-banner";
import { Rewards } from "@components/homepage/rewards";
import { Support } from "@components/homepage/support";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@components/new-ui/accordion";
import { getRecentGames } from "@components/recent-games";
import { WIN_FEED_LIMIT } from "@components/wins-feed";
import { getSlotList } from "@hooks/use-slots-list";
import { NODE_API } from "@lib/api/api";
import { inHouseGames } from "@lib/tools/house-games";
import { logError } from "@lib/tools/logger";
import { fetchPageContent, getSSRHeaders } from "@lib/tools/ssr";
import { GetServerSidePropsContext } from "next";
import dynamic from "next/dynamic";
import Head from "next/head";
const WinsFeedHome = dynamic(() => import("@components/wins-feed").then(m => m.WinsFeedHome));
const ProviderList = dynamic(() => import("@components/provider-list"));
const SlotsList = dynamic(() => import("@components/slots-list"));
const MetaContent = dynamic(() => import("@items/MetaContent"));
const schema = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Rainbet",
  url: "https://rainbet.com/"
};
export default function Home(props) {
  const {
    content,
    recommended,
    sportsbook,
    releases,
    gamesHistory
  } = props;
  return <main data-sentry-component="Home" data-sentry-source-file="index.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: JSON.stringify(schema)
      }} />
      </Head>
      <MetaContent meta={content?.meta} data-sentry-element="MetaContent" data-sentry-source-file="index.tsx" />
      <HomepageBanner data-sentry-element="HomepageBanner" data-sentry-source-file="index.tsx" />
      <section className="border-solid border border-r-0 border-l-0 border-white/5 py-6">
        <div className="max-w-[1200px] w-full mx-auto px-4">
          <WinsFeedHome initialData={gamesHistory} data-sentry-element="WinsFeedHome" data-sentry-source-file="index.tsx" />
        </div>
      </section>
      <section className="pt-10 pb-14 flex-col flex gap-10">
        <div className="max-w-[1200px] w-full mx-auto px-4 flex-col flex gap-10">
          <SlotsList heading="recommended" sortBy="recommended" carousel initialData={recommended} data-sentry-element="SlotsList" data-sentry-source-file="index.tsx" />

          <SlotsList isStatic carousel heading="originals" initialData={{
          count: inHouseGames.length,
          games: inHouseGames
        }} number={inHouseGames.length} data-sentry-element="SlotsList" data-sentry-source-file="index.tsx" />

          <SlotsList isStatic carousel heading="sportsbook" initialData={{
          count: sportsbook.length,
          games: sportsbook as any
        }} number={sportsbook.length} data-sentry-element="SlotsList" data-sentry-source-file="index.tsx" />

          <SlotsList heading="newReleases" sortBy="release" carousel initialData={releases} data-sentry-element="SlotsList" data-sentry-source-file="index.tsx" />

          <ProviderList heading="providers" carousel default_sort_by={"popular"} data-sentry-element="ProviderList" data-sentry-source-file="index.tsx" />
        </div>
      </section>
      <Rewards data-sentry-element="Rewards" data-sentry-source-file="index.tsx" />
      <Support data-sentry-element="Support" data-sentry-source-file="index.tsx" />
      <PaymentBanner data-sentry-element="PaymentBanner" data-sentry-source-file="index.tsx" />

      {content?.content && <section className="mb-11">
          <div className="max-w-[1200px] w-full mx-auto px-4">
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1" className="border border-solid border-white/5 rounded-md overflow-hidden">
                <AccordionTrigger className="bg-[#282D49] px-5 h-[50px] hover:no-underline text-sm font-medium">
                  About Rainbet Crypto Casino
                </AccordionTrigger>
                <AccordionContent className="bg-[#171b34] p-[15px_20px]">
                  <Content __html={content?.content} />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </section>}
    </main>;
}
export async function getServerSideProps(context: GetServerSidePropsContext) {
  try {
    context.res.setHeader("Cache-Control", "public, s-maxage=10, stale-while-revalidate=59");
    const {
      token,
      ...headers
    } = await getSSRHeaders(context);
    const locale = context.locale;
    if (token) {
      return {
        redirect: {
          destination: locale === "en" ? "/casino" : `/${locale}/casino`
        }
      };
    }
    const [content, recommended, sportsbook, releases, recentGames, gamesHistory] = await Promise.all([fetchPageContent(context), getSlotList({
      sort_by: "recommended",
      num: 36
    }, headers).catch(() => []), NODE_API.get("v1/sports/list", {
      params: {
        sort_by: "recommended"
      },
      headers
    }).then(res => res?.data?.sports || []).catch(() => []), getSlotList({
      sort_by: "release",
      num: 36
    }, headers).catch(() => null), getRecentGames(token, headers).catch(() => []), NODE_API.post("v1/game-history", {
      wins: true,
      take: WIN_FEED_LIMIT + 1
    }, {
      headers
    }).then(res => res.data).catch(() => [])] as const);
    return {
      props: {
        content,
        recentGames,
        recommended,
        sportsbook,
        releases,
        gamesHistory,
        ip: headers["ssr-client-ip"] || null
      }
    };
  } catch (error) {
    logError("pages/index.jsx", "getServerSideProps", "Failed to fetch data", error.toString());
    return {
      props: {
        error: error.toString()
      }
    };
  }
}