import { Game } from "@hooks/use-slots-list";
import { gameImageMap } from "../../pages/casino/originals/[game]";

export const inHouseGames: Game[] = [
  {
    id: 12,
    name: "games_limbo_title",
    url: "limbo",
    icon: gameImageMap["limbo"],
    producer: "Rainbet",
    payout: "",
    custom_banner: null,
    type: "",
    region_blocked: false,
    new: true,
  },
  {
    id: 11,
    name: "games_blackjack_title",
    url: "blackjack",
    icon: gameImageMap["blackjack"],
    producer: "Rainbet",
    payout: "",
    custom_banner: null,
    type: "",
    region_blocked: false,
    new: true,
  },
  {
    id: 9,
    name: "games_mines_title",
    url: "mines-game",
    icon: gameImageMap["mines"],
    producer: "Rainbet",
    payout: "",
    custom_banner: null,
    type: "",
    region_blocked: false,
    new: false,
  },
  {
    id: 10,
    name: "games_keno_title",
    url: "keno",
    icon: gameImageMap["keno"],
    producer: "Rainbet",
    payout: "",
    custom_banner: null,
    type: "",
    region_blocked: false,
    new: false,
  },
  {
    id: 3,
    name: "games_dice_title",
    url: "dice",
    icon: gameImageMap["dice"],
    producer: "Rainbet",
    payout: "",
    custom_banner: null,
    type: "",
    region_blocked: false,
    new: false,
  },
  {
    id: 4,
    name: "games_plinko_title",
    url: "plinko",
    icon: gameImageMap["plinko"],
    producer: "Rainbet",
    payout: "",
    custom_banner: null,
    type: "",
    region_blocked: false,
    new: false,
  },
  {
    id: 8,
    name: "games_case_battles_title",
    url: "case-battles",
    icon: gameImageMap["case-battles"],
    producer: "Rainbet",
    payout: "",
    custom_banner: null,
    type: "",
    region_blocked: false,
    new: false,
  },
  {
    id: 1,
    name: "games_case_opening_title",
    url: "case-opening",
    icon: gameImageMap["case-opening"],
    producer: "Rainbet",
    payout: "",
    custom_banner: null,
    type: "",
    region_blocked: false,
    new: false,
  },
] as const;
