import { BoostGameplayIcon, CalendarRewardIcon, DailyWeeklyMonthlyIcon, LevelUpIcon } from "@assets/icons/homepage-icons";
import { Button } from "@components/new-ui/button";
import Image from "next/image";
import { motion } from "motion/react";
import { useEffect, useState } from "react";
import RewardIcons, { RewardCode } from "@items/RewardIcons";
import { cn } from "@lib/utils";
import Link from "next/link";
import { Dict } from "@hooks/useLanguage";
import { usePreferences } from "@contexts/PreferencesContext";
const displaySequence = [null, {
  code: "bronze"
}, {
  code: "silver"
}, {
  code: "gold"
}, {
  code: "platinum"
}, {
  code: "diamond"
}, null];
export function Rewards() {
  const {
    preferences
  } = usePreferences();
  const [currentIndex, setCurrentIndex] = useState(2);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prev => {
        if (prev >= displaySequence.length - 1) return 2;
        return prev + 1;
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);
  return <section className="mb-[75px] py-[45px] [@media(min-width:680px)]:py-[75px] relative w-full bg-[#1B1F38] border-t border-b border-solid border-[#FFFFFF]/5" data-sentry-component="Rewards" data-sentry-source-file="rewards.tsx">
      <div className="absolute inset-0 z-0">
        <Image src="/Images/homepage-reward-bg.png" alt="Rewards background" fill quality={100} priority={true} className="object-cover object-center" sizes="100vw" style={{
        width: "100%",
        height: "100%"
      }} data-sentry-element="Image" data-sentry-source-file="rewards.tsx" />
      </div>

      <div className="max-w-[1200px] w-full mx-auto flex flex-col gap-10 relative z-10">
        <Dict name="homepage_rewards_title" as="h2" section="common" className="text-[25px] [@media(min-width:680px)]:text-[30px] font-semibold leading-[120%] tracking-[-0.48px] text-center px-8 [@media(min-width:680px)]:px-0 w-full [@media(min-width:680px)]:max-w-[615px] [@media(min-width:680px)]:mx-auto" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />

        <div className="max-w-[1008px] w-full mx-auto flex bg-[#1B1F38]/80 rounded-md backdrop-blur">
          <div className={cn("flex-1 flex flex-col gap-5 p-6", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1350px)]:flex-row" : "[@media(min-width:1100px)]:flex-row")}>
            <div className={cn("relative h-full flex flex-col gap-3", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1450px)]:min-w-[465px]" : preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1350px)]:min-w-[465px]" : !preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1100px)]:min-w-[465px]" : "[@media(min-width:1200px)]:min-w-[465px]")}>
              <div className="w-full relative rounded-md p-[1px] overflow-hidden">
                <div className="absolute inset-0 bg-border-gradient-homepage z-0 rounded-md" />
                <div className={cn("z-10 bg-[#383F61] relative py-[22px] px-6 overflow-hidden rounded-md flex items-start gap-7 min-h-[100px]", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1450px)]:items-center" : preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1350px)]:items-center" : !preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1100px)]:items-center" : "[@media(min-width:1200px)]:items-center")}>
                  <div className="w-14 h-14 relative flex items-center justify-center bg-[radial-gradient(ellipse_100.00%_104.20%_at_50.05%_100.09%,_rgba(57,_242,_110,_0.75)_0%,_rgba(57,_242,_110,_0.10)_100%)] rounded-[5px] border-2 border-[#22c55e] border-solid">
                    <DailyWeeklyMonthlyIcon data-sentry-element="DailyWeeklyMonthlyIcon" data-sentry-source-file="rewards.tsx" />
                  </div>

                  <div className="flex flex-col gap-3">
                    <Dict name="homepage_rewards_daily_weekly_monthly_bonuses" as="h3" section="common" className="text-white text-lg font-semibold" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />
                    <Dict name="homepage_rewards_daily_weekly_monthly_bonuses_description" as="p" section="common" className="text-white/75 text-sm font-normal" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />
                  </div>
                </div>
                <div className="absolute z-[11] left-1 bottom-0 bg-gradient-to-r from-[#39F26E] via-[#39F26E] to-[#39F26E]/0 h-[1.5px] w-[75%]"></div>
              </div>

              <div className="w-full relative rounded-md p-[1px] overflow-hidden">
                <div className="absolute inset-0 bg-border-gradient-homepage z-0 rounded-md" />
                <div className="z-10 bg-[#383F61] relative py-[22px] px-6 overflow-hidden rounded-md flex items-center gap-7 min-h-[100px]">
                  <div className="w-14 h-14 flex items-center justify-center relative bg-[radial-gradient(ellipse_100.00%_104.20%_at_50.05%_100.09%,_rgba(179,_92,_248,_0.75)_0%,_rgba(179,_92,_248,_0.10)_100%)] rounded-[5px] border-2 border-[#a855f7] border-solid">
                    <CalendarRewardIcon data-sentry-element="CalendarRewardIcon" data-sentry-source-file="rewards.tsx" />
                  </div>

                  <div className="flex flex-col gap-3">
                    <Dict name="homepage_rewards_calendar_rewards" as="h3" section="common" className="text-white text-lg font-semibold" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />
                  </div>
                </div>
              </div>

              <div className="w-full relative rounded-md p-[1px] overflow-hidden">
                <div className="absolute inset-0 bg-border-gradient-homepage z-0 rounded-md" />
                <div className="z-10 bg-[#383F61] relative py-[22px] px-6 overflow-hidden rounded-md flex items-center gap-7 min-h-[100px]">
                  <div className="w-14 h-14 relative flex items-center justify-center bg-[radial-gradient(ellipse_100.00%_104.20%_at_50.05%_100.09%,_rgba(255,_205.91,_31.88,_0.75)_0%,_rgba(255,_206,_32,_0.10)_100%)] rounded-[5px] border-2 border-[#fcd34d] border-solid">
                    <LevelUpIcon data-sentry-element="LevelUpIcon" data-sentry-source-file="rewards.tsx" />
                  </div>

                  <div className="flex flex-col gap-3">
                    <Dict name="homepage_rewards_level_up_ranks" as="h3" section="common" className="text-white text-lg font-semibold" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />
                  </div>
                </div>
              </div>

              <div className="w-full relative rounded-md p-[1px] overflow-hidden">
                <div className="absolute inset-0 bg-border-gradient-homepage z-0 rounded-md" />
                <div className="z-10 bg-[#383F61] relative py-[22px] px-6 overflow-hidden rounded-md flex items-center gap-7 min-h-[100px]">
                  <div className="w-14 h-14 relative flex items-center justify-center bg-[radial-gradient(ellipse_100.00%_104.20%_at_50.05%_100.09%,_rgba(250,_80,_105.50,_0.75)_0%,_rgba(250,_80,_105.50,_0.10)_100%)] rounded-[5px] border-2 border-[#f43f5e] border-solid">
                    <BoostGameplayIcon data-sentry-element="BoostGameplayIcon" data-sentry-source-file="rewards.tsx" />
                  </div>

                  <div className="flex flex-col gap-3">
                    <Dict name="homepage_rewards_boost_your_gameplay" as="h3" section="common" className="text-white text-lg font-semibold" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />
                  </div>
                </div>
              </div>
            </div>
            <div className="h-full w-full p-5 rounded-md py-10 px-[32x] hidden [@media(min-width:680px)]:block">
              <div className="flex flex-col h-full justify-between">
                <Dict name="homepage_rewards_description" as="p" section="common" className="text-white text-2xl font-semibold text-center w-[250px] mx-auto" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />

                <div className="flex justify-center items-center h-[200px] my-8 relative">
                  <div className="w-[412px] overflow-hidden">
                    <motion.div animate={{
                    x: `${-138 * (currentIndex - 2)}px`
                  }} transition={{
                    duration: 0.5,
                    ease: "easeInOut"
                  }} className="flex gap-[7px]" data-sentry-element="unknown" data-sentry-source-file="rewards.tsx">
                      {displaySequence.map((item, index) => {
                      const isCenteredItem = index === currentIndex - 1;
                      return <motion.div key={`${item?.code ?? "null"}-${index}`} animate={{
                        scale: isCenteredItem ? 1.05 : 0.7,
                        opacity: isCenteredItem ? 1 : 0.5
                      }} transition={{
                        duration: 0.5
                      }} className={cn("w-[132px] flex-shrink-0 flex items-center justify-center")}>
                            {item ? <RewardIcons code={item.code as RewardCode} size="customHomepage" /> : <div className="w-[132px] bg-transparent flex items-center justify-center"></div>}
                          </motion.div>;
                    })}
                    </motion.div>
                  </div>
                </div>

                <div className="flex justify-center">
                  <Link href="/rewards" data-sentry-element="Link" data-sentry-source-file="rewards.tsx">
                    <Button variant="blue" data-sentry-element="Button" data-sentry-source-file="rewards.tsx">
                      <Dict name="homepage_rewards_explore_vip_rewards" as="span" section="common" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center [@media(min-width:680px)]:hidden">
          <Link href="/rewards" data-sentry-element="Link" data-sentry-source-file="rewards.tsx">
            <Button variant="blue" data-sentry-element="Button" data-sentry-source-file="rewards.tsx">
              <Dict name="homepage_rewards_explore_vip_rewards" as="span" section="common" data-sentry-element="Dict" data-sentry-source-file="rewards.tsx" />
            </Button>
          </Link>
        </div>
      </div>
    </section>;
}