import MetaMaskIcon from "@assets/icons/general/MetaMaskIcon";
import SteamIcon from "@assets/icons/general/SteamIcon";
import { ChevronDownIcon } from "@components/new-ui/accordion";
import { Button } from "@components/new-ui/button";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { Dict } from "@hooks/useLanguage";
import { motion } from "motion/react";
import { useExternalAuth } from "@hooks/useExternalAuth";
import { Popover, PopoverContent, PopoverTrigger } from "@components/new-ui/popover";
import { GoogleLogoModal } from "@/assets/icons/general/GoogleLogo";
import { ArrowRightIcon, BannerCasinoIcon, BannerSportsbookIcon } from "@/assets/icons/homepage-icons";
import { usePreferences } from "@contexts/PreferencesContext";
import { cn } from "@lib/utils";
export function HomepageBanner() {
  const pathname = usePathname();
  const {
    preferences
  } = usePreferences();
  const {
    metaMaskLogin,
    steam,
    googleLogin
  } = useExternalAuth();
  return <section className="py-7 relative w-full" data-sentry-component="HomepageBanner" data-sentry-source-file="banner.tsx">
      <div className="absolute inset-0 z-0">
        <Image src="/Images/homepage-banner-bg.png" alt="Banner background" fill quality={100} priority={true} className="object-cover object-center" sizes="100vw" data-sentry-element="Image" data-sentry-source-file="banner.tsx" />
      </div>

      <div className={cn("max-w-[1200px] w-full mx-auto flex [@media(max-width:640px)]:flex-col gap-6 relative z-10 px-4 [@media(min-width:640px)_and_(max-width:1100px)]:flex-col [@media(min-width:640px)_and_(max-width:1100px)]:gap-7", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1650px)]:flex-col [@media(min-width:1100px)_and_(max-width:1650px)]:gap-7 [@media(min-width:1650px)]:flex-row [@media(min-width:1650px)]:gap-20" : preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1500px)]:flex-col [@media(min-width:1100px)_and_(max-width:1500px)]:gap-7 [@media(min-width:1500px)]:flex-row [@media(min-width:1500px)]:gap-20" : !preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1350px)]:flex-col [@media(min-width:1100px)_and_(max-width:1350px)]:gap-7 [@media(min-width:1350px)]:flex-row [@media(min-width:1350px)]:gap-20" : "")}>
        <div className={cn("flex flex-col justify-center gap-8 max-w-[447px] text-white [@media(min-width:640px)_and_(max-width:1100px)]:mx-auto [@media(min-width:640px)_and_(max-width:1100px)]:text-center", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1650px)]:mx-auto [@media(min-width:1100px)_and_(max-width:1650px)]:max-w-[570px] [@media(min-width:1100px)_and_(max-width:1650px)]:text-center" : preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1500px)]:mx-auto [@media(min-width:1100px)_and_(max-width:1500px)]:max-w-[570px] [@media(min-width:1100px)_and_(max-width:1500px)]:text-center" : !preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1350px)]:mx-auto [@media(min-width:1100px)_and_(max-width:1350px)]:max-w-[570px] [@media(min-width:1100px)_and_(max-width:1350px)]:text-center" : "")}>
          <motion.div initial={{
          opacity: 0,
          y: 20
        }} animate={{
          opacity: 1,
          y: 0
        }} transition={{
          duration: 0.5
        }} data-sentry-element="unknown" data-sentry-source-file="banner.tsx">
            <Dict name="homepage_banner_title" as="h1" section="common" className="text-[28px] font-semibold leading-[120%] tracking-[-0.42px]" data-sentry-element="Dict" data-sentry-source-file="banner.tsx" />
          </motion.div>

          <div className={cn("flex gap-4 items-center text-white [@media(min-width:640px)_and_(max-width:1100px)]:mx-auto", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1650px)]:mx-auto" : preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1500px)]:mx-auto" : !preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1350px)]:mx-auto" : "")}>
            <div className="bg-[#344065] p-[5px] rounded-md border-solid border border-[#435075]">
              <motion.div whileHover="hover" data-sentry-element="unknown" data-sentry-source-file="banner.tsx">
                <Link href={{
                pathname,
                query: {
                  modal: "auth",
                  tab: "register"
                }
              }} data-sentry-element="Link" data-sentry-source-file="banner.tsx">
                  <Button variant="normal" className="h-[48px] min-h-[48px] px-8 flex gap-[14px] border-solid border border-[#B0E8BC] bg-[#38C758] hover:bg-[#38C758]/80 hover:border-[#B0E8BC]/80 transition-all duration-300 font-semibold text-[15px] group [@media(max-width:400px)]:px-2" data-sentry-element="Button" data-sentry-source-file="banner.tsx">
                    <Dict name="homepage_register_now" as="span" section="common" data-sentry-element="Dict" data-sentry-source-file="banner.tsx" />
                    <motion.svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" variants={{
                    hover: {
                      x: 4,
                      transition: {
                        type: "spring",
                        stiffness: 300,
                        damping: 10
                      }
                    }
                  }} data-sentry-element="unknown" data-sentry-source-file="banner.tsx">
                      <motion.path fillRule="evenodd" clipRule="evenodd" d="M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5ZM14.6701 10.0028L10.2301 5.56279C9.95768 5.29033 9.51594 5.29033 9.24348 5.56279C8.97102 5.83525 8.97102 6.27699 9.24348 6.54945L12.4924 9.79842H5.80469C5.41937 9.79842 5.10701 10.1108 5.10701 10.4961C5.10701 10.8814 5.41937 11.1938 5.80469 11.1938H12.4924L9.24348 14.4427C8.97102 14.7152 8.97102 15.1569 9.24348 15.4294C9.51594 15.7019 9.95768 15.7019 10.2301 15.4294L14.6701 10.9894C14.9426 10.717 14.9426 10.2752 14.6701 10.0028Z" fill="white" variants={{
                      hover: {
                        fill: "#B0E8BC",
                        transition: {
                          duration: 0.2
                        }
                      }
                    }} data-sentry-element="unknown" data-sentry-source-file="banner.tsx" />
                    </motion.svg>
                  </Button>
                </Link>
              </motion.div>
            </div>
            <span className="text-[#E8E5FF]/50 text-xs font-medium [@media(max-width:500px)]:hidden">
              Or
            </span>

            <div className="bg-[#384572]/30 rounded-md border-solid border border-[#435075] h-[58px] flex items-center justify-center">
              <button onClick={steam} className="flex items-center justify-center w-[42px] h-full hover:bg-[#435075]/10 transition-all duration-300">
                <SteamIcon data-sentry-element="SteamIcon" data-sentry-source-file="banner.tsx" />
              </button>

              <button onClick={metaMaskLogin} className="flex items-center justify-center w-[42px] h-full hover:bg-[#435075]/10 transition-all duration-300">
                <MetaMaskIcon data-sentry-element="MetaMaskIcon" data-sentry-source-file="banner.tsx" />
              </button>

              <Popover data-sentry-element="Popover" data-sentry-source-file="banner.tsx">
                <PopoverTrigger className="group flex items-center justify-center cursor-pointer w-[42px] h-full rounded-none border-t-0 border-b-0 border-r-0 border-l border-solid border-l-[#48567E] data-[state=open]:bg-[#435075]/20 hover:bg-[#435075]/20 transition-all duration-300" data-sentry-element="PopoverTrigger" data-sentry-source-file="banner.tsx">
                  <div className="w-[28px] h-[28px] border border-solid border-[#48567E] bg-white/5 rounded-full flex items-center justify-center">
                    <ChevronDownIcon className="w-4.5 h-2.5 group-data-[state=open]:rotate-180 transition-transform" data-sentry-element="ChevronDownIcon" data-sentry-source-file="banner.tsx" />
                  </div>
                </PopoverTrigger>
                <PopoverContent className="min-w-[286px] bg-[#222E53] border border-solid border-[#435075] rounded-md p-2.5" align="end" data-sentry-element="PopoverContent" data-sentry-source-file="banner.tsx">
                  <div className="grid grid-cols-2 gap-1.5">
                    <Button variant="normal" onClick={steam} className="h-[40px] min-h-[40px] gap-2.5 px-3 flex items-center justify-start bg-[#313E66] border-white/10" data-sentry-element="Button" data-sentry-source-file="banner.tsx">
                      <SteamIcon data-sentry-element="SteamIcon" data-sentry-source-file="banner.tsx" />{" "}
                      <span className="text-[13px] font-medium text-[#E8E5FF]">
                        Steam
                      </span>
                    </Button>

                    <Button variant="normal" onClick={metaMaskLogin} className="h-[40px] min-h-[40px] gap-2.5 px-3 flex items-center justify-start bg-[#313E66] border-white/10" data-sentry-element="Button" data-sentry-source-file="banner.tsx">
                      <MetaMaskIcon data-sentry-element="MetaMaskIcon" data-sentry-source-file="banner.tsx" />{" "}
                      <span className="text-[13px] font-medium text-[#E8E5FF]">
                        Metamask
                      </span>
                    </Button>

                    <Button variant="normal" onClick={googleLogin} className="h-[40px] min-h-[40px] gap-2.5 px-3 flex items-center justify-start bg-[#313E66] border-white/10" data-sentry-element="Button" data-sentry-source-file="banner.tsx">
                      <GoogleLogoModal data-sentry-element="GoogleLogoModal" data-sentry-source-file="banner.tsx" />{" "}
                      <span className="text-[13px] font-medium text-[#E8E5FF]">
                        Google
                      </span>
                    </Button>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>

        <div className={cn("flex gap-4 w-full justify-end", "[@media(min-width:640px)_and_(max-width:1100px)]:items-center", "[@media(min-width:640px)_and_(max-width:1100px)]:justify-center", "[@media(max-width:410px)]:flex-col", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1650px)]:items-center [@media(min-width:1100px)_and_(max-width:1650px)]:justify-center" : preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1500px)]:items-center [@media(min-width:1100px)_and_(max-width:1500px)]:justify-center" : !preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1100px)_and_(max-width:1350px)]:items-center [@media(min-width:1100px)_and_(max-width:1350px)]:justify-center" : "")}>
          <motion.div className="flex-1 w-full relative p-[1px] rounded-md bg-gradient-to-b from-[#5F7495] to-[#161E2B]/10 max-w-[305.91px] hover:-translate-y-[3px] transition-all duration-300" initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} transition={{
          duration: 0.5
        }} data-sentry-element="unknown" data-sentry-source-file="banner.tsx">
            <Link href="/casino" data-sentry-element="Link" data-sentry-source-file="banner.tsx">
              <div className="bg-[#252B46] overflow-hidden relative bg-[radial-gradient(ellipse_100.00%_74.33%_at_50.00%_100.00%,_rgba(0,_119,_219,_0.50)_0%,_rgba(0,_119,_219,_0.50)_100%)] rounded-md cursor-pointer">
                <div className="h-[200px] [@media(min-width:1100px)]:h-[241px] relative">
                  <Image src="/Images/banner-casino.png" alt="Banner casino" className="w-full h-full object-cover" fill data-sentry-element="Image" data-sentry-source-file="banner.tsx" />
                </div>
                <div className="h-[46px] w-full px-5 flex items-center bg-gradient-to-r from-[#A6D6FF]/15 to-[#229AFF]/15 border-t border-solid border-white/20">
                  <div className="flex items-center gap-3">
                    <BannerCasinoIcon data-sentry-element="BannerCasinoIcon" data-sentry-source-file="banner.tsx" />
                    <Dict name="casino" as="h3" section="common" className="text-white text-[17px] [@media(min-width:1100px)]:text-lg font-semibold" data-sentry-element="Dict" data-sentry-source-file="banner.tsx" />
                    <ArrowRightIcon className="hidden [@media(min-width:1100px)]:block" data-sentry-element="ArrowRightIcon" data-sentry-source-file="banner.tsx" />
                  </div>
                </div>
              </div>
            </Link>
          </motion.div>

          <motion.div className="flex-1 w-full relative p-[1px] rounded-md bg-gradient-to-b from-[#5F7495] to-[#161E2B]/10 max-w-[305.91px] hover:-translate-y-[3px] transition-all duration-300" initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} transition={{
          duration: 0.5
        }} data-sentry-element="unknown" data-sentry-source-file="banner.tsx">
            <Link href="/sportsbook" data-sentry-element="Link" data-sentry-source-file="banner.tsx">
              <div className="bg-[#252B46] overflow-hidden relative bg-[radial-gradient(ellipse_100.00%_74.33%_at_50.00%_100.00%,_rgba(100.27,_122.79,_159.38,_0.50)_0%,_rgba(22,_30,_43,_0.50)_100%)] rounded-md cursor-pointer">
                <div className="h-[200px] [@media(min-width:1100px)]:h-[241px] relative">
                  <Image src="/Images/banner-sportsbook.png" alt="Banner sportsbook" className="w-full h-full object-cover" fill data-sentry-element="Image" data-sentry-source-file="banner.tsx" />
                </div>
                <div className="h-[46px] w-full px-5 flex items-center border-t border-solid border-[rgba(255,255,255,0.06)]">
                  <div className="flex items-center gap-3">
                    <BannerSportsbookIcon data-sentry-element="BannerSportsbookIcon" data-sentry-source-file="banner.tsx" />
                    <Dict name="sportsbook" as="h3" section="common" className="text-white text-[17px] [@media(min-width:1100px)]:text-lg font-semibold" data-sentry-element="Dict" data-sentry-source-file="banner.tsx" />
                    <ArrowRightIcon className="hidden [@media(min-width:1100px)]:block" data-sentry-element="ArrowRightIcon" data-sentry-source-file="banner.tsx" />
                  </div>
                </div>
              </div>
            </Link>
          </motion.div>
        </div>
      </div>
    </section>;
}