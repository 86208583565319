import { ChatSubmitIcon, ChevronRightIcon } from "@/assets/icons/homepage-icons";
import { Button } from "@components/new-ui/button";
import { usePreferences } from "@contexts/PreferencesContext";
import useLanguage, { Dict } from "@hooks/useLanguage";
import { cn } from "@lib/utils";
import Image from "next/image";
import { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "motion/react";
const supportItems = [{
  title: "support_question_1",
  description: "support_answer_1"
}, {
  title: "support_question_2",
  description: "support_answer_2"
}, {
  title: "support_question_3",
  description: "support_answer_3"
}, {
  title: "support_question_4",
  description: "support_answer_4"
}, {
  title: "support_question_5",
  description: "support_answer_5"
}];
const ContentSection = ({
  description
}: {
  description: string;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showGradient, setShowGradient] = useState(false);
  const checkScroll = () => {
    if (scrollRef.current) {
      const {
        scrollHeight,
        clientHeight,
        scrollTop
      } = scrollRef.current;
      const isScrollable = scrollHeight > clientHeight;
      const isAtBottom = scrollHeight - scrollTop - clientHeight < 10;
      setShowGradient(isScrollable && !isAtBottom);
    }
  };
  useEffect(() => {
    checkScroll();
  }, [description]);
  return <div className="relative flex-1 flex flex-col min-h-0" data-sentry-component="ContentSection" data-sentry-source-file="support.tsx">
      <div ref={scrollRef} onScroll={checkScroll} className="flex-1 overflow-y-auto pr-4 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/20 text-sm font-normal text-white leading-[140%] tracking-[0px]">
        <Dict name={description} as="p" section="common" className="text-white text-sm font-normal leading-[140%] tracking-[0px] [&_strong]:font-bold [&_ul]:list-disc [&_ul]:pl-4" data-sentry-element="Dict" data-sentry-source-file="support.tsx" />
      </div>

      {showGradient && <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-[#252B46] to-transparent pointer-events-none" />}
    </div>;
};
export function Support() {
  const {
    preferences
  } = usePreferences();
  const [message, setMessage] = useState("");
  const L = useLanguage(["common"]);
  const [selectedItem, setSelectedItem] = useState<{
    title: string;
    description: string;
  } | null>(null);
  const handleSendMessage = () => {
    if (message.trim() === "") return;
    if (window !== undefined) {
      window.Intercom("show");
      window.Intercom("showNewMessage", message);
    }
  };
  return <section className="max-w-[1200px] w-full mx-auto pb-[75px] flex flex-col gap-10" data-sentry-component="Support" data-sentry-source-file="support.tsx">
      <Dict name="homepage_rewards_title" as="h2" section="common" className="text-[25px] [@media(min-width:820px)]:text-[32px] font-semibold leading-[120%] tracking-[-0.48px] text-center px-8 [@media(min-width:820px)]:px-0 w-full [@media(min-width:820px)]:max-w-[615px] [@media(min-width:820px)]:mx-auto" data-sentry-element="Dict" data-sentry-source-file="support.tsx" />

      <div className="max-w-[1032px] w-full mx-auto flex px-4">
        <div className={cn("flex-1 flex flex-col", preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:flex-row" : preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1380px)]:flex-row" : "[@media(min-width:820px)]:flex-row")}>
          <div className={cn("relative w-full min-h-[166px] overflow-hidden border-solid border-2 border-[#39F26E] rounded-tr-[10.95px] rounded-tl-[10.95px]", preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:max-w-[342px] [@media(min-width:1250px)]:min-h-[490px] [@media(min-width:1250px)]:rounded-tr-none [@media(min-width:1250px)]:rounded-bl-[10.95px]" : preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1380px)]:max-w-[342px] [@media(min-width:1380px)]:min-h-[490px] [@media(min-width:1380px)]:rounded-tr-none [@media(min-width:1380px)]:rounded-bl-[10.95px]" : "[@media(min-width:820px)]:max-w-[342px] [@media(min-width:820px)]:min-h-[490px] [@media(min-width:820px)]:rounded-tr-none [@media(min-width:820px)]:rounded-bl-[10.95px]")}>
            <div className="absolute inset-0 z-0">
              <Image src="/Images/homepage-support-bg.png" alt="Support background" fill quality={100} priority={true} className="object-cover object-center" sizes="100vw" data-sentry-element="Image" data-sentry-source-file="support.tsx" />
            </div>

            <div className={cn("absolute inset-0 -bottom-4 z-10 flex flex-col items-center gap-4 p-4", preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:justify-between [@media(min-width:1250px)]:gap-0" : preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1380px)]:justify-between [@media(min-width:1380px)]:gap-0" : "[@media(min-width:820px)]:justify-between [@media(min-width:820px)]:gap-0")}>
              <div className="w-full max-w-[102.61px] relative h-[34.88px]">
                <Image src="/Images/homepage-support-top.svg" alt="Top content" fill className="object-contain" data-sentry-element="Image" data-sentry-source-file="support.tsx" />
              </div>

              <div className={cn("w-full max-w-[106.55px] relative h-[68.4px] flex items-center justify-center", preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:max-w-[153.14px] [@media(min-width:1250px)]:h-[98.6px]:gap-0" : preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1380px)]:max-w-[153.14px] [@media(min-width:1380px)]:h-[98.6px]" : "[@media(min-width:820px)]:max-w-[153.14px] [@media(min-width:820px)]:h-[98.6px]")}>
                <Image src="/Images/homepage-support-middle.svg" alt="Middle content" fill className="object-contain" data-sentry-element="Image" data-sentry-source-file="support.tsx" />
              </div>

              <div className={cn("w-full max-w-[250px] relative h-[120px] hidden", preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:block" : preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1380px)]:block" : "[@media(min-width:820px)]:block")}>
                <Image src="/Images/homepage-support-bottom.svg" alt="Bottom content" fill className="object-contain" data-sentry-element="Image" data-sentry-source-file="support.tsx" />
              </div>
            </div>
          </div>

          <div className={cn("w-full relative p-[1px] rounded-t-0 rounded-b-[10.95px] overflow-hidden [@media(max-width:820px)]:pt-0 [@media(min-width:820px)]:pl-0", preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:rounded-l-none [@media(min-width:1250px)]:rounded-r-[10.95px]" : preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1380px)]:rounded-l-none [@media(min-width:1380px)]:rounded-r-[10.95px]" : "[@media(min-width:820px)]:rounded-l-none [@media(min-width:820px)]:rounded-r-[10.95px]")}>
            <div className="absolute inset-0 bg-border-gradient-homepage z-0" />
            <div className={cn("relative h-full w-full p-5 rounded-t-0 rounded-b-[10.95px] overflow-hidden z-10 bg-[#0f1328]", preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:border-l-0 [@media(min-width:1250px)]:rounded-l-none [@media(min-width:1250px)]:rounded-r-[10.95px] [@media(min-width:1250px)]:border-t" : preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1380px)]:border-l-0 [@media(min-width:1380px)]:rounded-l-none [@media(min-width:1380px)]:rounded-r-[10.95px] [@media(min-width:1380px)]:border-t" : "[@media(min-width:820px)]:border-l-0 [@media(min-width:820px)]:rounded-l-none [@media(min-width:820px)]:rounded-r-[10.95px] [@media(min-width:820px)]:border-t")}>
              <div className="relative bg-[#252B46] rounded-md p-6 border border-solid border-white/10 flex z-10 flex-col-reverse [@media(min-width:820px)]:flex-col gap-6 justify-between h-full">
                <div className="flex items-center flex-col gap-[10px]">
                  {supportItems.map((item, index) => <div key={index} onClick={() => setSelectedItem(item)} className="w-full py-[17px] rounded-md text-white px-6 text-sm font-medium leading-[130%] bg-[#383F61]/50 flex items-center justify-between hover:bg-[#383F61]/75 cursor-pointer transition-all duration-300">
                      <Dict name={item.title} as="span" section="common" />
                      <ChevronRightIcon />
                    </div>)}
                </div>
                <div className="w-full relative bg-[#181C34]/75 rounded-[10.95px] p-2 text-sm font-medium leading-[130%] min-h-[58px]">
                  <div className="h-[42px] pr-4 relative">
                    <input type="text" placeholder={L("homepage_support_chat_message") as string} className="h-full bg-transparent px-6 [@media(min-width:820px)]:pr-[160px] w-full text-white placeholder:text-[#E8E5FF]/50 text-sm font-normal focus:outline-none" onChange={e => setMessage(e.target.value)} />

                    <div className="absolute top-0 right-0 rounded-md">
                      <Button variant="ghost" className="h-[42px] min-h-[42px] w-[42px] px-0 [@media(min-width:820px)]:hidden flex items-center justify-center" onClick={handleSendMessage} data-sentry-element="Button" data-sentry-source-file="support.tsx">
                        <ChatSubmitIcon data-sentry-element="ChatSubmitIcon" data-sentry-source-file="support.tsx" />
                      </Button>
                      <Button variant="blue" className="hidden [@media(min-width:820px)]:block h-[42px] min-h-[42px] px-8" onClick={handleSendMessage} data-sentry-element="Button" data-sentry-source-file="support.tsx">
                        <Dict name="homepage_support_chat_with_us" as="span" section="common" data-sentry-element="Dict" data-sentry-source-file="support.tsx" />
                      </Button>
                    </div>
                  </div>
                </div>

                <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="support.tsx">
                  {selectedItem && <motion.div initial={{
                  opacity: 0,
                  x: "100%"
                }} animate={{
                  opacity: 1,
                  x: 0
                }} exit={{
                  opacity: 0,
                  x: "100%"
                }} transition={{
                  duration: 0.3,
                  ease: "easeInOut"
                }} className="absolute inset-0 bg-[#252B46] border border-solid border-white/10 rounded-md z-50 flex flex-col min-h-0">
                      <div className="p-6 flex flex-col gap-5 min-h-0 flex-1">
                        <div>
                          <Button variant="normal" className="h-[33px] min-h-[33px] bg-[#383F61] px-3 gap-1 hover:bg-[#383F61]/80 border-[#383F61] hover:border-[#383F61]/80 text-[#E8E5FF]/75" onClick={() => setSelectedItem(null)}>
                            <ChevronRightIcon className="rotate-180 !h-[9px] [&_path]:fill-[#E8E5FF]/75" />{" "}
                            Go back
                          </Button>
                        </div>

                        <Dict name={selectedItem.title} as="h2" section="common" className="text-white text-[22px] font-medium leading-[130%] tracking-[0px]" />
                        <div className="h-[1px] bg-white/10 w-full"></div>
                        <ContentSection description={selectedItem.description} />
                      </div>
                    </motion.div>}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
}