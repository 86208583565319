import { ApplePayIcon, BitcoinCash, BitcoinIcon, EthereumIcon, GooglePayIcon, LitecoinIcon, MastercardIcon, PixIcon, ShieldIcon, TetherIcon, VisaIcon } from "@assets/icons/homepage-icons";
import { Dict } from "@hooks/useLanguage";
import { cn } from "@lib/utils";
import Image from "next/image";
import { usePreferences } from "@contexts/PreferencesContext";
export const PaymentBanner = () => {
  const {
    preferences
  } = usePreferences();
  return <section className="mb-14 bg-[#252B46] border-solid border border-r-0 border-l-0 border-white/5" data-sentry-component="PaymentBanner" data-sentry-source-file="payment-banner.tsx">
      <div className="max-w-[1200px] w-full mx-auto px-4">
        <div className={cn("py-8 flex flex-col gap-8", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1450px)]:py-14 [@media(min-width:1450px)]:flex-row [@media(min-width:1450px)]:items-center" : preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:py-14 [@media(min-width:1250px)]:flex-row [@media(min-width:1250px)]:items-center" : !preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1100px)]:py-14 [@media(min-width:1100px)]:flex-row [@media(min-width:1100px)]:items-center" : "[@media(min-width:1200px)]:py-14 [@media(min-width:1200px)]:flex-row [@media(min-width:1200px)]:items-center")}>
          <ShieldIcon className={cn("hidden shrink-0", preferences.chatOpen && !preferences.miniSide ? "[@media(min-width:1450px)]:block" : preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1250px)]:block" : !preferences.chatOpen && preferences.miniSide ? "[@media(min-width:1100px)]:block" : "[@media(min-width:1200px)]:block")} data-sentry-element="ShieldIcon" data-sentry-source-file="payment-banner.tsx" />
          <div className="flex flex-col gap-1 min-w-0">
            <div className="flex items-center gap-[10px]">
              <ShieldIcon className="shrink-0 [@media(min-width:995px)]:hidden w-[24.5px] h-[29.5px]" data-sentry-element="ShieldIcon" data-sentry-source-file="payment-banner.tsx" />
              <Dict name="homepage_payment_banner_preferred_payment_methods" as="p" section="common" className="text-white text-lg font-medium break-words" data-sentry-element="Dict" data-sentry-source-file="payment-banner.tsx" />
            </div>
            <Dict name="homepage_payment_banner_preferred_payment_methods_description" as="p" section="common" className="text-white/75 text-sm font-normal break-words hyphens-auto" data-sentry-element="Dict" data-sentry-source-file="payment-banner.tsx" />
          </div>

          <div className="flex items-center gap-[18px] shrink-0 [@media(max-width:550px)]:justify-between">
            <div className="flex gap-0 items-center -space-x-4 grow-0">
              <BitcoinIcon className="h-[39.6px] w-[39.6px] z-[5]" data-sentry-element="BitcoinIcon" data-sentry-source-file="payment-banner.tsx" />
              <LitecoinIcon className="h-[39.6px] w-[39.6px] z-[4]" data-sentry-element="LitecoinIcon" data-sentry-source-file="payment-banner.tsx" />
              <EthereumIcon className="h-[39.6px] w-[39.6px] z-[3]" data-sentry-element="EthereumIcon" data-sentry-source-file="payment-banner.tsx" />
              <TetherIcon className="h-[39.6px] w-[39.6px] z-[2]" data-sentry-element="TetherIcon" data-sentry-source-file="payment-banner.tsx" />
              <BitcoinCash className="h-[39.6px] w-[39.6px] z-[1]" data-sentry-element="BitcoinCash" data-sentry-source-file="payment-banner.tsx" />
            </div>

            <PixIcon className="h-[36px]" data-sentry-element="PixIcon" data-sentry-source-file="payment-banner.tsx" />

            <Image src="/Images/interac.png" alt="Interac" width={35.99} height={35.99} quality={100} data-sentry-element="Image" data-sentry-source-file="payment-banner.tsx" />

            <div className="flex flex-col [@media(min-width:550px)]:flex-row justify-center gap-[1px]items-center shrink-0">
              <div className="flex gap-[1px]">
                <VisaIcon className="h-[26px]" data-sentry-element="VisaIcon" data-sentry-source-file="payment-banner.tsx" />
                <MastercardIcon className="h-[26px]" data-sentry-element="MastercardIcon" data-sentry-source-file="payment-banner.tsx" />
              </div>
              <div className="flex gap-[1px]">
                <GooglePayIcon className="h-[26px]" data-sentry-element="GooglePayIcon" data-sentry-source-file="payment-banner.tsx" />
                <ApplePayIcon className="h-[26px]" data-sentry-element="ApplePayIcon" data-sentry-source-file="payment-banner.tsx" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
};